body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: white!important;
  /* min-width: 1200px; */
  /* line-height: 1; */
  background-color: #fff!important;
}

*{
  margin:0; 
  padding:0;
  box-sizing: border-box;
}
body, div, p, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, button, textarea, p, blockquote, th, td { 
  margin: 0; padding: 0; font-family:'SourceHanSansCN-Normal','SourceHanSansCN-Medium','Microsoft YaHei','PingFang-SC;'; }
fieldset, img { border: 0; }
ul, ol, li { list-style: none;  }

.container{
  /* width: 1200px; */
  width: 1200px;
  margin: 0 auto;
}
body p, h1, h2, h3, h4, h5, h6{
  margin-bottom: 0;
}
body button{
  border: none;
  outline: none;
}
#root{
  height: 100%;
}
@font-face {
  font-family: '方正兰亭黑简体';
  src: url('./font/方正兰亭黑简体.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'webfont';
  src: url('./font/webfont.ttf') format('truetype');
}

/* @font-face {
  font-family: 'SourceHanSansCN-Light';
  src: url('./font/SourceHanSansCN-Light.otf') format('truetype');
}

@font-face {
  font-family: 'SourceHanSansCN-Medium';
  src: url('./font/SourceHanSansCN-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'SourceHanSansCN-Normal';
  src: url('./font/SourceHanSansCN-Normal.otf') format('truetype');
} */

.h1Title{
  opacity: 0;
  position: absolute;
  z-index: -1;
}